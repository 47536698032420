<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div
          class="d-flex justify-content-between align-items-center pt-50 mb-2"
        >
          <div>
            <h2 class="font-weight-bolder m-0">
              Logs de Acceso
            </h2>
          </div>
          <div class="d-flex align-items-center">
            <b-input-group class="input-group">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchTerm"
                type="text"
                class="d-inline-block"
                :placeholder="'Búsqueda'"
                @keydown.enter.native="handleSearch"
              />
              <b-button
                variant="primary"
                size="sm"
                class="mr-1 ml-1"
                @click="handleSearch"
              >
                {{ "Buscar" }}
              </b-button>
            </b-input-group>
            <span
                class="ml-1 cursor-pointer"
                @click="visibleFilter = !visibleFilter"
            >
                <feather-icon
                    icon="FilterIcon"
                    size="20"
                />
              </span>
          </div>
        </div>
        <div>
          <b-collapse
              id="collapse"
              v-model="visibleFilter"
              class="mt-2"
          >
            <div class="px-2">
              <b-row>
                <b-col sm="4">
                  <b-form-group
                      :label="'Fecha inicio'"
                      label-for="filter-fechaini"
                  >
                    <flat-pickr
                        v-model="date_ini"
                        name="fecha_ini"
                        class="form-control"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group
                      :label="'Fecha fin'"
                      label-for="filter-fechafin"
                  >
                    <flat-pickr
                        v-model="date_fin"
                        name="fecha_ini"
                        class="form-control"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-collapse>
        </div>
        <b-card no-body>
          <b-table
            hover
            responsive
            :items="items"
            :fields="columns"
          >
            <template #cell(fullName)="data">
              <div class="d-flex align-items-center">
                <span class="ml-1">
                  <b-link
                    :to="{ name: 'viewUser', params: { id: data.item.user_id } }"
                    class="link"
                  >
                    {{ data.item.user.name }} {{ data.item.user.surname }}
                  </b-link>
                </span>
              </div>
            </template>
            <template #cell(status)="data">
              <b-badge :variant="statusVariant(data.item.active)">
                {{ statusVariantText(data.item.active) }}
              </b-badge>
            </template>
            <template #cell(created_at)="data">
              {{ TimestampToFlatPickrWithTime(data.item.created_at) }}
            </template>
          </b-table>
          <div
            class="d-flex align-items-center justify-content-between mb-1 px-2"
          >
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ "Por página" }}: </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ "Total" }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge,
  BPagination,
  BFormInput,
  BFormSelect,
  BCard,
  BTable,
  BButton,
  BCol,
  BRow,
  BLink,
  BInputGroup,
  BInputGroupPrepend, BFormGroup, BCollapse,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'
import flatPickr from 'vue-flatpickr-component'
import {FlatPickrToTimestamp, TimestampToFlatPickr, TimestampToFlatPickrWithTime} from '@/libs/helpers'

export default {
  components: {
    BCollapse,
    BFormGroup,
    BBadge,
    BCard,
    BPagination,
    BFormInput,
    BFormSelect,
    BTable,
    BButton,
    BCol,
    BRow,
    BLink,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
  },
  data() {
    return {
      log: [],
      visibleFilter: false,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      department: '',
      pages: ['10', '25', '50'],
      columns: [
        {
          label: 'Nombre',
          key: 'fullName',
        },
        {
          label: 'Email',
          key: 'user.email',
        },
        {
          label: 'Fecha y Hora',
          key: 'created_at',
        },
        {
          label: 'Acción',
          key: 'action',
        },
      ],
      searchTerm: '',
      date_ini: '',
      date_fin: '',
      orderby: '',
    }
  },
  computed: {
    ...mapGetters({
      items: 'users/getAccessLogs',
      totalItems: 'users/getTotalAccessLogs',
    }),
  },
  created() {
    this.chargeData()
  },
  methods: {
    TimestampToFlatPickrWithTime,
    TimestampToFlatPickr,
    ...mapActions({
      list: 'users/getAccessUsers',
    }),
    chargeData() {
      let filterDateIni = ''
      let filterDateFin = ''
      if (this.date_ini) {
        filterDateIni = FlatPickrToTimestamp(this.date_ini)
      }
      if (this.date_fin) {
        filterDateFin = FlatPickrToTimestamp(this.date_fin)
      }
      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        date_ini: filterDateIni,
        date_fin: filterDateFin,
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  watch: {
    date_ini() {
      this.chargeData()
    },
    date_fin() {
      this.chargeData()
    },
  }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.dropdown-user{
  list-style-type: none;
}
[dir] .b-nav-dropdown .dropdown-toggle::after{
  display: none;
}
</style>
